import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Typography, ThemeProvider } from '@one-thd/sui-atomic-components';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { Carousel } from '@one-thd/sui-carousel';
import styles from './visual-navigation.scss';
import { VisualNavigationCard } from './VisualNavigationCard';

const VisualNavigationRenderer = ({
  componentId,
  componentClass = '',
  componentPosition = 1,
  isInSection = false,
  lazyLoad = false,
  dataComponent = null,
  visNavList = null,
  experienceHasSideNav = false,
  enableTheme = false,
  fontWeight = 'display'
}) => {

  const cx = classNames.bind(styles);
  const title = dataComponent?.title;
  let { theme, itemTheme } = dataComponent || {};
  const isCarousel = dataComponent?.overflowBehavior === 'Carousel';

  theme = enableTheme ? theme : 'DefaultTheme';
  itemTheme = enableTheme ? itemTheme : 'DefaultTheme';

  const getGridClass = (length) => {
    if (length === 3) {
      return '';
    }
    if ([4, 7, 8, 16].includes(length)) {
      return 'md:sui-grid-cols-4';
    }
    if ([5, 9, 10, 13, 14, 15].includes(length)) {
      return 'md:sui-grid-cols-4 lg:sui-grid-cols-5';
    }
    return 'md:sui-grid-cols-4 lg:sui-grid-cols-6';
  };

  const cardCollectionWrap = (
    <div
      className={classNames('card-tile__collection',
        'sui-grid',
        'sui-gap-4',
        'sui-grid-cols-2',
        'sm:sui-grid-cols-3',
        getGridClass(visNavList?.length)
      )}
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-testid="visual-navigation"
      data-component-id={componentId}
      data-component-name={componentClass}
      data-component-position={componentPosition}
      data-component="VisualNavigation"
    >
      {visNavList.map((item, idx) => {
        const visNavListKey = `visual-navigation-${idx + 1}`;
        return (
          <VisualNavigationCard
            item={item}
            key={visNavListKey}
            position={idx + 1}
            lazyLoad={lazyLoad}
            theme={getThemeByName(itemTheme)}
          />
        );
      })}
    </div>
  );

  const contents = [];
  if (isCarousel) {
    for (let i = 0; i < visNavList?.length; i += 1) {
      contents.push(
        <div
          className={cx('card-tile__collection')}
          id={`${componentClass}-${componentPosition}-${componentId}`}
          data-testid="visual-navigation"
          data-component-id={componentId}
          data-component-name={componentClass}
          data-component-position={componentPosition}
          data-component="VisualNavigation"
        >
          <VisualNavigationCard
            item={visNavList[i]}
            key={i + 1}
            position={i + 1}
            lazyLoad={lazyLoad}
            theme={getThemeByName(itemTheme)}
          />
        </div>
      );
    }
  }

  const cardCarousel = (
    <ThemeProvider theme={getThemeByName(theme)}>
      <Carousel
        autoplay={false}
        disableMargin
        peek={false}
        slidesPerGroup={2}
        slidesPerView={2}
        spaceBetween={16}
        breakpoints={{
          sm: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 16
          },
          md: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 16
          },
          lg: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 16
          },
          xl: {
            slidesPerView: experienceHasSideNav ? 4 : 6,
            slidesPerGroup: experienceHasSideNav ? 4 : 6,
            spaceBetween: 16
          }
        }}
      >
        {contents}
      </Carousel>
    </ThemeProvider>
  );

  const cardCollection = isCarousel ? cardCarousel : cardCollectionWrap;

  return (
    <ThemeProvider theme={getThemeByName(theme)}>
      <div className="sui-bg-primary sui-text-primary">
        {isInSection ? cardCollection
          : (
            <>
              <div className={cx('sui-pb-4')}>
                <Typography variant="h2" weight={fontWeight} height="snug" color="primary" uppercase>
                  {title}
                </Typography>
              </div>
              <ImpressionProvider
                key={componentPosition}
                data={{
                  id: componentId,
                  component: 'Section',
                  name: 'Section',
                  type: 'content'
                }}
              >
                {cardCollection}
              </ImpressionProvider>
            </>
          )}
      </div>
    </ThemeProvider>
  );
};

VisualNavigationRenderer.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  isInSection: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  experienceHasSideNav: PropTypes.bool,
  enableTheme: PropTypes.bool,
  fontWeight: PropTypes.string,
  dataComponent: PropTypes.shape({
    title: PropTypes.string,
    overflowBehavior: PropTypes.string
  }),
  visNavList: PropTypes.arrayOf(PropTypes.shape({
    altText: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    previewImage: PropTypes.shape({
      damContentSelector: PropTypes.shape({
        assetData: PropTypes.arrayOf(
          PropTypes.shape({
            selectedImageUrl: PropTypes.string
          })
        )
      }),
      damDownloadedContent: PropTypes.shape({
        url: PropTypes.string
      })
    })
  }))
};

export { VisualNavigationRenderer };
