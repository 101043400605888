/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  arrayOf,
  bool,
  extend, fragment,
  params,
  shape, string,
  useDataModel,
  number
} from '@thd-nucleus/data-sources';

import { WorkshopsMetaData } from '@thd-olt-component-react/metadata';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { Typography } from '@one-thd/sui-atomic-components';

import { WorkshopsCard } from '../internal-components/WorkshopsCard';
import { Loading } from '../../internal-components/Loading';

const AvailableEventsLegacy = () => {
  const workshopsMetaData = {
    alternateUrlEnCa: 'https://www.homedepot.ca/workshops',
    alternateUrlFrCa: 'https://www.homedepot.ca/ateliers',
    alternateUrlEsMx: 'https://www.homedepot.com.mx/talleres-hazlo-tu-mismo',
    data: {
      canonicalURL: '/workshops/',
      description: 'The Home Depot offers free do-it-yourself workshops for all ages and '
        + 'experience levels. Discover livestream and digital project how-tos for kids, '
        + 'Homeowner 101 and other DIY workshops.',
      siteName: 'The Home Depot',
      fbAdmins: '100005416960152',
      title: 'DIY Workshops | The Home Depot',
      type: 'Workshops'
    },
    title: 'DIY Workshops | The Home Depot',
    description: 'The Home Depot offers free do-it-yourself workshops for all ages and '
      + 'experience levels. Discover livestream and digital project how-tos for kids, '
      + 'Homeowner 101 and other DIY workshops.'
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
        'nucleus-exp-workshops.page-load',
        { ...workshopsMetaData }
      );
    }
  }, []);

  const { data, loading } = useDataModel('layouts', {
    variables: {
      slug: 'workshops'
    },
  });
  if (loading === true) return <Loading />;
  if (loading === false && data === null) return null;

  return (
    <>
      <ErrorBoundary name="metadata">
        <WorkshopsMetaData
          alternateUrlEnCa={workshopsMetaData.alternateUrlEnCa}
          alternateUrlFrCa={workshopsMetaData.alternateUrlFrCa}
          alternateUrlEsMx={workshopsMetaData.alternateUrlEsMx}
          data={workshopsMetaData.data}
          description={workshopsMetaData.description}
          title={workshopsMetaData.title}
        />
      </ErrorBoundary>
      <ErrorBoundary name="available-events-content">
        <div className="sui-m-4 lg:sui-m-24">
          <div className="sui-grid sui-gap-6">
            <div className="sui-col-span-12">
              <Typography variant="h1" weight="condensed">{data?.layouts?.content?.title}</Typography>
            </div>
            <div className="sui-col-span-12">
              <Typography variant="body-lg" weight="regular" height="tight">
                {data?.layouts?.content?.introduction}
              </Typography>
            </div>
          </div>
          {
            data?.layouts?.content?.workshopsSections?.map((section, i) => {
              return (
                <div>
                  <div className="sui-py-10" key={i}>
                    <Typography variant="h3" weight="bold" height="tight">
                      {section?.title}
                    </Typography>
                  </div>
                  <div className="sui-grid sm:sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-4 sui-gap-4">
                    {
                      section?.workshopsCards?.map((card, key) => {
                        const cardImage = { title: '', url: '' };
                        if (card?.image?.damDownloadedContent) {
                          cardImage.title = card.image.damDownloadedContent.title;
                          cardImage.url = card.image.damDownloadedContent.url;
                        } else if (card?.image?.damContentSelector) {
                          cardImage.title = card.image.damContentSelector.assetData[0].productName;
                          cardImage.url = card.image.damContentSelector.assetData[0].selectedImageUrl;
                        }
                        return (
                          <ErrorBoundary name={`WorkshopsCard: ${key}`}>
                            <WorkshopsCard
                              key={key}
                              name={card?.title}
                              imageAlt={cardImage.title}
                              imagePath={cardImage.url}
                              logoAlt={null}
                              logoPath={null}
                              callToActionButtonText={card?.cta}
                              callToActionUrl={card?.link}
                              description={card?.description}
                              registrationFlag={card?.registrationFlag}
                            />
                          </ErrorBoundary>
                        );
                      })
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </ErrorBoundary>
    </>
  );
};

AvailableEventsLegacy.displayName = 'AvailableEventsLegacy';
AvailableEventsLegacy.propTypes = {};
AvailableEventsLegacy.defaultProps = {};
AvailableEventsLegacy.dataModel = extend({
  layouts: params({
    slug: string().isRequired()
  }).shape({
    id: string(),
    publishDate: string(),
    content: shape({
      WorkshopsPage: fragment().shape({
        introduction: string(),
        title: string(),
        workshopsSections: arrayOf(shape({
          title: string(),
          workshopsCards: arrayOf(shape({
            cta: string(),
            description: string(),
            link: string(),
            image: shape({
              title: string(),
              damDownloadedContent: {
                title: string(),
                url: string(),
                contentType: string(),
                componentName: string(),
                description: string(),
                details: shape({
                  height: number(),
                  size: number(),
                  width: number(),
                }),
                fileName: string(),
                id: string(),
                publishDate: string(),
              },
              damContentSelector: shape({
                assetData: arrayOf(shape({
                  additionalOMSIDs: string(),
                  fileName: string(),
                  idmguid: string(),
                  omsid: string(),
                  productName: string(),
                  recordId: string(),
                  selectedImageUrl: string(),
                  selectedImageDimensions: string(),
                }))
              })
            }),
            registrationFlag: bool(),
            title: string(),
            __typename: string()
          }))
        }))
      })
    })
  })
});

export { AvailableEventsLegacy };
