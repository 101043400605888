/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-cond-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable id-length */
import React from 'react';

// Style mappings for different content types
const styleMap = {
  h1: ['sui-text-4xl sui-font-bold sui-mb-6'],
  h2: ['sui-text-3xl sui-font-bold sui-mb-5'],
  h3: ['sui-text-2xl sui-font-bold sui-mb-4'],
  h4: ['sui-text-xl sui-font-bold sui-mb-3'],
  h5: ['sui-text-lg sui-font-bold sui-mb-2'],
  h6: ['sui-text-base sui-font-bold sui-mb-2'],
  p: ['sui-text-base sui-pb-4'],
  ul: ['sui-text-base sui-list-disc sui-list-inside sui-pl-2 sui-pb-4'],
  ol: ['sui-text-base sui-list-decimal sui-list-inside sui-pl-2 sui-pb-4'],
  li: ['sui-mb-1'],
  a: [
    'sui-font-regular sui-text-base sui-tracking-normal',
    'sui-normal-case sui-line-clamp-unset sui-font-normal',
    'sui-text-primary focus-visible:sui-bg-focus',
    'sui-underline hover:sui-text-primary-dark',
  ],
  blockquote: [
    'sui-text-base sui-pl-4 sui-border-l-4 sui-border-gray-300',
    'sui-italic sui-my-4'
  ],
  hr: ['sui-my-6 sui-border-t sui-border-gray-300'],
  table: ['sui-min-w-full sui-border-collapse sui-mb-4'],
  th: ['sui-border sui-border-gray-300 sui-p-2 sui-bg-gray-100 sui-font-bold'],
  td: ['sui-border sui-border-gray-300 sui-p-2'],
  tr: ['sui-border-b sui-border-gray-300'],
  code: ['sui-font-mono sui-bg-gray-100 sui-px-1 sui-rounded'],
  pre: ['sui-bg-gray-100 sui-p-4 sui-rounded sui-overflow-x-auto sui-mb-4'],
};

const parseMarkdown = (text) => {
  // Split text into blocks
  const blocks = text.split(/\n\n+/);

  return blocks.map((block, blockIndex) => {
    // Handle headings
    const headingMatch = block.match(/^(#{1,6})\s+(.+)$/);
    if (headingMatch) {
      const level = headingMatch[1].length;
      const content = headingMatch[2];
      return createHeadingNode(`h${level}`, content, blockIndex);
    }

    // Handle unordered lists
    if (block.match(/^[*-]\s/m)) {
      return createListNode('ul', block, blockIndex);
    }

    // Handle ordered lists
    if (block.match(/^\d+\.\s/m)) {
      return createListNode('ol', block, blockIndex);
    }

    // Handle blockquotes
    if (block.startsWith('>')) {
      return createBlockquoteNode(block.substring(1).trim(), blockIndex);
    }

    // Handle horizontal rules
    if (block.match(/^[-*_]{3,}$/)) {
      return createNode('hr', '', blockIndex);
    }

    // Handle tables
    if (block.includes('|')) {
      return createTableNode(block, blockIndex);
    }

    // Default to paragraph
    return createParagraphNode(block, blockIndex);
  });
};

const createNode = (type, content, key, attributes = {}) => ({
  type: 'tag',
  name: type,
  key,
  attribs: {
    className: styleMap[type]?.join(' ') || '',
    ...attributes
  },
  children: Array.isArray(content) ? content : [{
    type: 'text',
    data: content
  }]
});

const createHeadingNode = (level, content, key) => createNode(level, processInlineMarkdown(content), key);

const createListNode = (type, content, key) => {
  const items = content.split('\n')
    .filter((item) => item.trim())
    .map((item, i) => {
      // Extract just the content after the marker, handling both unordered and ordered lists
      const itemContent = item.replace(/^(?:[-*]|\d+\.)\s+/, '').trim();
      return createNode('li', processInlineMarkdown(itemContent), `${key}_${i}`);
    });
  return createNode(type, items, key);
};

const createBlockquoteNode = (content, key) => createNode('blockquote', processInlineMarkdown(content), key);

const createTableNode = (content, key) => {
  const rows = content.split('\n').filter((row) => row.trim());
  const headers = rows[0].split('|').map((cell) => cell.trim()).filter(Boolean);
  const alignments = rows[1]?.split('|')
    .map((cell) => cell.trim())
    .filter(Boolean)
    .map((cell) => {
      if (cell.startsWith(':') && cell.endsWith(':')) return 'center';
      if (cell.endsWith(':')) return 'right';
      return 'left';
    });

  const tableRows = rows.slice(2).map((row, rowIndex) => {
    const cells = row.split('|')
      .map((cell) => cell.trim())
      .filter(Boolean)
      .map((cell, cellIndex) => createNode('td', processInlineMarkdown(cell), `${key}_${rowIndex}_${cellIndex}`, {
        style: { textAlign: alignments[cellIndex] || 'left' }
      })
      );
    return createNode('tr', cells, `${key}_row_${rowIndex}`);
  });

  const headerCells = headers.map((header, i) => createNode('th', processInlineMarkdown(header), `${key}_header_${i}`, {
    style: { textAlign: alignments[i] || 'left' }
  })
  );

  const headerRow = createNode('tr', headerCells, `${key}_header_row`);
  return createNode('table', [headerRow, ...tableRows], key);
};

const createParagraphNode = (content, key) => createNode('p', processInlineMarkdown(content), key);

const processInlineMarkdown = (text) => {
  let processedText = text;
  const fragments = [];

  // Process inline elements in order
  const patterns = [
    { pattern: /\*(.*?)\*/g, tag: 'em', class: 'sui-italic' },
    { pattern: /__(.*?)__/g, tag: 'b', class: 'sui-font-bold' },
    { pattern: /`(.*?)`/g, tag: 'code', class: styleMap.code.join(' ') },
    { pattern: /\^(.*?)\^/g, tag: 'sup', class: 'sui-text-xs sui-relative sui-top-[-0.5em]' },
    { pattern: /~(.*?)~/g, tag: 'sub', class: 'sui-text-xs sui-relative sui-bottom-[-0.5em]' },
    {
      pattern: /\[([^\]]+)\]\(([^)]+)\)/g,
      process: (match, text, url) => createNode('a', text, null, { href: url, target: '_blank', rel: 'noopener noreferrer' })
    }
  ];

  let lastIndex = 0;
  let match;

  patterns.forEach(({ pattern, tag, class: className, process }) => {
    while ((match = pattern.exec(processedText)) !== null) {
      // Add text before the match
      if (match.index > lastIndex) {
        fragments.push({
          type: 'text',
          data: processedText.slice(lastIndex, match.index)
        });
      }

      // Add the processed element
      if (process) {
        fragments.push(process(match, match[1], match[2]));
      } else {
        fragments.push({
          type: 'tag',
          name: tag,
          attribs: { className },
          children: [{ type: 'text', data: match[1] }]
        });
      }

      lastIndex = match.index + match[0].length;
    }
  });

  // Add remaining text
  if (lastIndex < processedText.length) {
    fragments.push({
      type: 'text',
      data: processedText.slice(lastIndex)
    });
  }

  return fragments.length > 0 ? fragments : [{ type: 'text', data: text }];
};

const MarkdownRenderer = ({ content }) => {
  const nodes = parseMarkdown(content);

  return (
    <div className="sui-prose">
      {nodes.map((node, index) => {
        if (node.type === 'text') {
          return <React.Fragment key={index}>{node.data}</React.Fragment>;
        }

        return React.createElement(
          node.name,
          {
            key: node.key || index,
            className: node.attribs.className,
            ...node.attribs
          },
          Array.isArray(node.children)
            ? node.children.map((child, childIndex) => (child.type === 'text'
              ? <React.Fragment key={childIndex}>{child.data}</React.Fragment>
              : React.createElement(
                child.name,
                {
                  key: childIndex,
                  className: child.attribs.className,
                  ...child.attribs
                },
                child.children[0].data
              ))
            )
            : node.children
        );
      })}
    </div>
  );
};

export default MarkdownRenderer;