import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import MarkdownRenderer from './MarkdownRenderer';

export const RichText = ({
  title,
  description,
}) => {
  return (
    <div>
      <div className="sui-mb-4">
        <Typography variant="h2" weight="display" uppercase>
          {title}
        </Typography>
      </div>
      <MarkdownRenderer content={description} />
    </div>
  );
};

RichText.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
};
RichText.defaultProps = {};