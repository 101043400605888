import React from 'react';
import classNames from 'classnames';
import { Skeleton, SkeletonLine, SkeletonBlock } from '@one-thd/sui-atomic-components';

export const Loading = () => {
  return (
    <div
      className={classNames(
        'sui-flex sui-flex-col sui-bg-primary sui-gap-6 sui-px-4 sui-py-4',
        'md:sui-px-6 lg:sui-px-8 xl:sui-px-auto'
      )}
    >
      <Skeleton grow>
        <SkeletonLine fullWidth variant="single" />
        <SkeletonBlock aspect="wide" height={32} />
        <SkeletonLine fullWidth variant="single" />
        <div className="sui-grid sui-gap-4 md:sui-gap-6 xl:sui-gap-8 sui-grid-cols-1 md:sui-grid-cols-2 xl:sui-grid-cols-3">
          <SkeletonBlock aspect="square" />
          <SkeletonBlock aspect="square" />
          <SkeletonBlock aspect="square" />
          <SkeletonBlock aspect="square" />
          <SkeletonBlock aspect="square" />
          <SkeletonBlock aspect="square" />
        </div>
        <SkeletonLine fullWidth />
        <SkeletonBlock aspect="wide" height={32} />
        <SkeletonLine fullWidth />
      </Skeleton>
    </div>
  );
};
