import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

import { Alert, Typography } from '@one-thd/sui-atomic-components';
import {
  arrayOf,
  bool,
  extend, fragment,
  params,
  shape, string,
  useDataModel,
  number
} from '@thd-nucleus/data-sources';

import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { WorkshopsMetaData } from '@thd-olt-component-react/metadata';

import { KidsWorkshopsRegistration } from '@thd-olt-component-react/kids-workshops-registration';
import { BrandHero } from '@thd-olt-component-react/hero';
import { MedioInline, MedioComposableBanner } from '@thd-olt-component-react/internal-marketing-banner';
import { Spotlight, SpotlightContainer } from '@thd-olt-component-react/spotlight';
import { ContentAccordion } from '@thd-olt-component-react/content-accordion';
import { VisualNavigation } from '@thd-olt-component-react/visual-navigation';
import { Resource } from '@thd-olt-component-react/resource';
import { ContentSection } from '@thd-olt-component-react/content-section';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { SideNavigation } from '@thd-olt-component-react/side-navigation';

import { RichText } from '../../internal-components/RichText';
import { Loading } from '../../internal-components/Loading';

const AvailableEvents = () => {
  const experienceContext = useContext(ExperienceContext);
  const { customer, isDebugMode } = experienceContext;
  const store = useStore({ varnish: false });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showNoStoreMessage, setShowNoStoreMessage] = useState(false);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handleClick = (event) => {
    event.preventDefault();

    const link = event.target.closest('a');
    if (!link) return;

    if (!store?.storeId) {
      setShowNoStoreMessage(true);
      return;
    }

    setIsDrawerOpen(true);
  };

  const workshopsMetaData = {
    alternateUrlEnCa: 'https://www.homedepot.ca/workshops',
    alternateUrlFrCa: 'https://www.homedepot.ca/ateliers',
    alternateUrlEsMx: 'https://www.homedepot.com.mx/talleres-hazlo-tu-mismo',
    data: {
      canonicalURL: '/workshops/',
      description: 'Explore free DIY workshops for all ages and '
        + 'experience levels. Find livestream and digital project '
        + 'how-tos to help you tackle any project with confidence.',
      siteName: 'The Home Depot',
      fbAdmins: '100005416960152',
      title: 'Free DIY Workshops & Classes',
      type: 'Workshops'
    },
    title: 'Free DIY Workshops & Classes',
    description: 'Explore free DIY workshops for all ages and '
        + 'experience levels. Find livestream and digital project '
        + 'how-tos to help you tackle any project with confidence.'
  };

  const { data, loading } = useDataModel('layouts', {
    variables: {
      slug: 'workshops'
    },
  });

  const experienceHasSideNav = !!data?.layouts?.content?.sideNavigation?.id;
  const sideNavData = data?.layouts?.content?.sideNavigation || null;
  const crumbs = data?.layouts?.breadcrumbs?.breadcrumbItem || [];

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
        'nucleus-exp-workshops.page-load'
      );
    }
  }, []);

  if (loading === true) return <Loading />;
  if (loading === false && data === null) return null;

  return (
    <div
      className={classNames(
        'sui-flex sui-flex-col sui-bg-primary sui-gap-6 sui-px-4 sui-py-4',
        'md:sui-px-6 lg:sui-px-8 xl:sui-px-auto '
      )}
    >
      <ErrorBoundary name="metadata">
        <WorkshopsMetaData
          alternateUrlEnCa={workshopsMetaData.alternateUrlEnCa}
          alternateUrlFrCa={workshopsMetaData.alternateUrlFrCa}
          alternateUrlEsMx={workshopsMetaData.alternateUrlEsMx}
          data={workshopsMetaData.data}
          description={workshopsMetaData.description}
          title={workshopsMetaData.title}
        />
      </ErrorBoundary>

      <ErrorBoundary name="available-events-content">
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <div className="sui-flex sui-flex-col sui-w-full sui-max-w-[1376px] xl:sui-self-center sui-gap-6">
          <ErrorBoundary name="breadcrumbs">
            <Breadcrumbs staticCrumbs={crumbs} />
          </ErrorBoundary>

          <div>
            <Typography variant="h1" weight="display" uppercase>
              {data?.layouts?.content?.title}
            </Typography>
          </div>

          <div
            className={classNames(
              'sui-grid sui-grid-rows-1 sui-gap-4 sui-grid-cols-1',
              { 'lg:sui-grid-cols-12': experienceHasSideNav },
            )}
          >
            {
              experienceHasSideNav
              && (
                <div
                  className={classNames(
                    'sui-hidden lg:sui-col-span-2 lg:[&>div]:sui-block lg:sui-block',
                  )}
                >
                  <SideNavigation
                    componentId={sideNavData.id}
                    componentClass={sideNavData.componentName}
                  />
                </div>
              )
            }
            <div
              className={classNames(
                'sui-col-span-1',
                { 'lg:sui-col-span-10 lg:sui-col-start-3': experienceHasSideNav },
              )}
            >
              <div className="sui-flex sui-flex-col sui-gap-8">
                {/* HERO */}
                <div className="sui-border-1 sui-rounded-base sui-border-solid sui-border-primary">
                  <BrandHero
                    componentId={data?.layouts?.content?.heroCarousel?.components[0].id}
                    componentClass="Hero"
                    hasSidenav={experienceHasSideNav}
                  />
                </div>
                {
                  data.layouts.content.flexibleCluster1.map((section) => {
                    return (
                      <div id={section.id}>
                        <ContentSection title={section.title}>
                          {section.components
                            .filter((subSection) => subSection.__typename !== 'ContentAccordion')
                            .map((subSection) => {
                              switch (subSection.__typename) {
                              case 'MedioComposableBanner':
                                if (subSection.title.toLocaleLowerCase().includes('kids workshops')) {
                                  return (
                                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                    <div onClick={handleClick}>
                                      <Alert
                                        status="info"
                                        className={classNames(
                                          { 'sui-hidden': !showNoStoreMessage }
                                        )}
                                        onClose={() => setShowNoStoreMessage(false)}
                                      >
                                        To register for a Kids Workshop, please select your store
                                      </Alert>
                                      <div className="[&>div>div>div>div]:sui-h-full">
                                        <MedioComposableBanner
                                          componentId={subSection.id}
                                          componentClass={subSection.__typename}
                                        />
                                      </div>
                                    </div>
                                  );
                                }

                                return (
                                  <div className="[&>div>div>div>div]:sui-h-full">
                                    <MedioComposableBanner
                                      componentId={subSection.id}
                                      componentClass={subSection.__typename}
                                    />
                                  </div>
                                );
                              case 'MedioInline':
                                return (
                                  <MedioInline
                                    componentId={subSection.id}
                                    componentClass={subSection.__typename}
                                  />
                                );
                              case 'SpotlightContainer':
                                return (
                                  <SpotlightContainer
                                    componentId={subSection.id}
                                    componentClass={subSection.__typename}
                                  />
                                );
                              case 'VisualNavigation':
                                return (
                                  <VisualNavigation
                                    componentId={subSection.id}
                                    componentClass={subSection.__typename}
                                    isInSection
                                  />
                                );
                              case 'Resource':
                                return (
                                  <Resource
                                    componentId={subSection.id}
                                    componentClass={subSection.__typename}
                                  />
                                );
                              default:
                                return null;
                              }
                            })}
                        </ContentSection>

                        {section.components
                          .filter((subSection) => subSection.__typename === 'ContentAccordion')
                          .map((subSection) => (
                            <div className="sui-mb-3">
                              <ContentAccordion
                                componentId={subSection.id}
                                componentClass={subSection.__typename}
                              />
                            </div>
                          ))}
                      </div>
                    );
                  })
                }

                {
                  data?.layouts?.content?.richText.map((rTextData, key) => {
                    return (
                      <RichText
                        key={'rtext-' + key}
                        title={rTextData.title}
                        description={rTextData.descriptionText}
                      />
                    );
                  })
                }
              </div>
            </div>
          </div>

          <KidsWorkshopsRegistration
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            svocId={customer.svocId}
            oldStoreId={store?.storeId}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};

AvailableEvents.displayName = 'AvailableEvents';
AvailableEvents.propTypes = {};
AvailableEvents.defaultProps = {};
AvailableEvents.dataModel = extend({
  layouts: params({
    slug: string().isRequired()
  }).shape({
    id: string(),
    publishDate: string(),
    breadcrumbs: shape({
      __typename: string(),
      Breadcrumbs: fragment().shape({
        __typename: string(),
        breadcrumbItem: shape({
          label: string(),
          url: string()
        })
      })
    }),
    content: shape({
      UniversalLayout: fragment().shape({
        id: string(),
        title: string(),
        flexibleCluster1: arrayOf(shape({
          id: string(),
          title: string(),
          isCarousel: bool(),
          anchorId: string(),
          components: arrayOf(shape({
            ...Spotlight.dataModel.component,
            ...SpotlightContainer.dataModel.specializedContainer,
            ...MedioInline.dataModel.component,
            ...MedioComposableBanner.dataModel.component,
            ...ContentAccordion.dataModel.component,
            ...VisualNavigation.dataModel.component,
            ...Resource.dataModel.component
          })),
          __typename: string(),
        })),
        heroCarousel: shape({
          id: string(),
          isCarousel: bool(),
          isAutoplay: bool(),
          components: arrayOf(shape({
            ...BrandHero.dataModel.brandedComponent,
          })),
          __typename: string(),
        }),
        sideNavigation: shape(SideNavigation.dataModel.component),
        richText: arrayOf(
          shape({
            title: string(),
            descriptionText: string(),
            __typename: string(),
          })
        ),
        __typename: string(),
      }),
    })
  })
},
BrandHero,
MedioInline,
Spotlight,
SpotlightContainer,
ContentAccordion,
MedioComposableBanner,
VisualNavigation,
Resource,
Breadcrumbs,
SideNavigation
);
export { AvailableEvents };