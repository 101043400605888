import React from 'react';
import { oneOfType, string as stringProp, number as numberProp } from 'prop-types';
import {
  arrayOf,
  bool,
  extend, fragment,
  params,
  shape, string,
  useDataModel,
  number
} from '@thd-nucleus/data-sources';
import { AvailableEvents } from './pages/available-events/AvailableEvents';
import { AvailableEventsLegacy } from './legacy/available-events/AvailableEventsLegacy';
import { Loading } from './internal-components/Loading';

export const AvailableEventsSelect = () => {
  const { data, loading } = useDataModel('layouts', {
    variables: {
      slug: 'workshops'
    },
  });
  if (loading === true) return <Loading />;
  if (data.layouts.content.__typename === 'WorkshopsPage') return <AvailableEventsLegacy />;

  return (
    <AvailableEvents />
  );
};

AvailableEventsSelect.dataModel = extend({
  workshops: params({ storeId: string().isRequired() }).shape({
    id: string(),
    storeId: string(),
    startDate: string(),
    endDate: string(),
    name: string(),
    description: string(),
    imagePath: string(),
    type: string(),
    registeredParticipants: number()
  }),
  storeSearch: params({
    storeSearchInput: string(),
    pagesize: string(),
  }).shape({
    stores: arrayOf(shape({
      storeId: string(),
      name: string(),
      address: shape({
        street: string(),
        city: string(),
        state: string(),
        postalCode: string(),
        country: string()
      }),
      phone: string(),
    })),
  }),
},
AvailableEvents,
AvailableEventsLegacy,
);
