import React from 'react';
import { oneOfType, string, number } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { AvailableEventsSelect } from '../../AvailableEventsSelect';

export const AvailableEventsContainer = ({ timeout, ...props }) => {
  const workshopsDefaultVariables = { storeId: '0121' };

  return (
    <QueryProvider
      cacheKey="workshops-available-events-page"
      defaultVariables={workshopsDefaultVariables}
    >
      <AvailableEventsSelect />
    </QueryProvider>
  );
};

AvailableEventsContainer.propTypes = {
  timeout: oneOfType([string, number])
};

AvailableEventsContainer.defaultProps = {
  timeout: '3000'
};
